*{
    margin:0;
    padding:0;
}
.webgl{
    position:fixed;
    top:0;
    left:0;
    outline: none;
}

html,
body{
    overlflow: hidden;
}